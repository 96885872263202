import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    APPLICATIONINSIGHTS_CONNECTION_STRING: z.string().optional(),
    APP_NAME_BASE: z.string().min(1),
    AZURE_AD_APP_ADMIN_GROUPS: z.string(),
    AZURE_AD_CLIENT_ID: z.string().min(1),
    AZURE_AD_CLIENT_SECRET: z.string().min(1),
    AZURE_AD_SUBSCRIPTION_ID: z.string().min(1),
    AZURE_AD_TENANT_ID: z.string().min(1),
    AZURE_AD_USER: z.string().default("system"),
    AZURE_DOCUMENT_INTELLIGENCE_ENDPOINT: z.string().min(1),
    AZURE_DOCUMENT_TRANSLATOR_ENDPOINT: z.string().min(1),
    AZURE_SEARCH_NAME: z.string().min(1),
    AZURE_STORAGE_ACCOUNT_NAME: z.string().min(1),
    AZURE_STORAGE_BLOB_CONTAINER_NAME: z.string().min(1),
    AZURE_TRANSLATOR_TARGET_CONTAINER_NAME: z.string().min(1),
    AZURE_TRANSLATOR_API_KEY: z.string().min(1),
    AZURE_TRANSLATOR_RESOURCE_ID: z.string().min(1),
    COMPOSE_PROJECT_NAME: z.string().min(1),
    DATADOG_APPLICATION_ID: z.string(),
    DATADOG_CLIENT_TOKEN: z.string(),
    DATADOG_ENV: z.string(),
    DATADOG_SERVICE: z.string(),
    DEPLOYMENT_BUILD_NUMBER: z.string().min(1),
    DEPLOYMENT_DATE: z.string().min(1),
    DEPLOYMENT_SHA: z.string().min(1),
    ENV: z.enum(["prod", "dev", "test", "local"]),
    FRONTEND_PORT: z.string().min(1).max(5),
    NEXTAUTH_SECRET: z.string().min(1),
    AUTH_TRUST_HOST: z.boolean(),
    NODE_ENV: z.enum(["development", "test", "production"]), // Next.js variable used to specify the run mode
    MIGRATION_SECRET: z.string(),
    OPENAI_API_BASE_URL: z.string().min(1),
    OPENAI_API_KEY: z.string().min(1),
    POSTGRES_DB: z.string().min(1),
    POSTGRES_PORT: z.number().min(1).max(99999),
    POSTGRES_HOST: z.string().min(1),
    POSTGRES_PASSWORD: z.string().min(1),
    POSTGRES_USER: z.string().min(1),
    POSTGRES_SSL_MODE: z.enum(["require", "disable"]).default("require"),
    POSTGRES_CONNECTION_POOL_MAX: z.string().min(1),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
    NEXT_PUBLIC_ENV: z.enum(["local", "dev", "test", "prod"]), // Used to specify where application is run
    NEXT_PUBLIC_VERSION_HASH: z.string().min(1),
    NEXT_PUBLIC_VERSION_READABLE: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */

  runtimeEnv: {
    APP_NAME_BASE: process.env.APP_NAME_BASE,
    APPLICATIONINSIGHTS_CONNECTION_STRING:
      process.env.APPLICATIONINSIGHTS_CONNECTION_STRING,
    AZURE_AD_CLIENT_ID: process.env.AZURE_AD_SERVICE_PRINCIPAL_ID_FRONTEND,
    AZURE_AD_CLIENT_SECRET:
      process.env.AZURE_AD_SERVICE_PRINCIPAL_SECRET_FRONTEND,
    AZURE_AD_SUBSCRIPTION_ID: process.env.AZURE_AD_SUBSCRIPTION_ID,
    AZURE_AD_TENANT_ID: process.env.AZURE_AD_TENANT_ID,
    AZURE_AD_APP_ADMIN_GROUPS: process.env.AZURE_AD_APP_ADMIN_GROUPS,
    AZURE_AD_USER: process.env.AZURE_AD_USER,
    AZURE_DOCUMENT_INTELLIGENCE_ENDPOINT:
      process.env.AZURE_DOCUMENT_INTELLIGENCE_ENDPOINT,
    AZURE_SEARCH_NAME: process.env.AZURE_SEARCH_NAME,
    AZURE_STORAGE_ACCOUNT_NAME: process.env.AZURE_STORAGE_ACCOUNT_NAME,
    AZURE_STORAGE_BLOB_CONTAINER_NAME:
      process.env.AZURE_STORAGE_BLOB_CONTAINER_NAME,
    AZURE_TRANSLATOR_TARGET_CONTAINER_NAME:
      process.env.AZURE_TRANSLATOR_TARGET_CONTAINER_NAME,
    AZURE_TRANSLATOR_API_KEY: process.env.AZURE_TRANSLATOR_API_KEY,
    AZURE_TRANSLATOR_RESOURCE_ID: process.env.AZURE_TRANSLATOR_RESOURCE_ID,
    AZURE_DOCUMENT_TRANSLATOR_ENDPOINT:
      process.env.AZURE_DOCUMENT_TRANSLATOR_ENDPOINT,
    COMPOSE_PROJECT_NAME: process.env.COMPOSE_PROJECT_NAME,
    DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
    DATADOG_ENV: process.env.DATADOG_ENV,
    DATADOG_SERVICE: process.env.DATADOG_SERVICE,
    DEPLOYMENT_BUILD_NUMBER: process.env.DEPLOYMENT_BUILD_NUMBER,
    DEPLOYMENT_DATE: process.env.DEPLOYMENT_DATE,
    DEPLOYMENT_SHA: process.env.DEPLOYMENT_SHA,
    ENV: process.env.ENV,
    FRONTEND_PORT: process.env.FRONTEND_PORT,
    NODE_ENV: process.env.NODE_ENV,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    AUTH_TRUST_HOST: process.env.AUTH_TRUST_HOST === "true",
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_VERSION_HASH: process.env.NEXT_PUBLIC_VERSION_HASH,
    NEXT_PUBLIC_VERSION_READABLE: process.env.NEXT_PUBLIC_VERSION_READABLE,
    MIGRATION_SECRET: process.env.MIGRATION_SECRET,
    OPENAI_API_BASE_URL: process.env.OPENAI_API_BASE_URL,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    POSTGRES_DB: process.env.POSTGRES_DB,
    POSTGRES_PORT: Number(process.env.POSTGRES_PORT),
    POSTGRES_HOST: process.env.POSTGRES_HOST,
    POSTGRES_PASSWORD: process.env.POSTGRES_PASSWORD,
    POSTGRES_USER: process.env.POSTGRES_USER,
    POSTGRES_SSL_MODE: process.env.POSTGRES_SSL_MODE,
    POSTGRES_CONNECTION_POOL_MAX: process.env.POSTGRES_CONNECTION_POOL_MAX,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
